import { Component, OnDestroy, OnInit } from '@angular/core'
import { HomeService } from '../../services-internal/home/home.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { ApprovedItemsCellRenderer } from '../../internal-Vpacs-gridrender/home/approved-items-cell-renderer'
import { ExportStateService } from 'src/app/services/export-state.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { WarnGridComponent } from '../../internal-Vpacs-gridrender/home/tracking-grid/warn-grid/warn-grid.component'
import { GoodGridComponent } from '../../internal-Vpacs-gridrender/home/tracking-grid/good-grid/good-grid.component'
import { DelayGridComponent } from '../../internal-Vpacs-gridrender/home/tracking-grid/delay-grid/delay-grid.component'
import { LogRendererComponent } from '../../internal-Vpacs-gridrender/home/log-renderer/log-renderer.component'
import { UpdatePpfRendererComponent } from '../../internal-Vpacs-gridrender/home/update-ppf-renderer/update-ppf-renderer.component'
import { ConstantsService } from 'src/app/services/constant.service'
import { UpdateUserComponent } from '../../internal-vpacs-dialogs/master-maintenance/update-user/update-user.component'
import { formatDate } from '@angular/common'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit,OnDestroy {
  columnDefs: any[] = []
  displayRowData: any[] = []
  columnDefs2: any[] = []
  displayRowData2: any[] = []
  columnDefs3: any[] = []
  displayRowData3: any[] = []
  columnDefs4: any[] = []
  displayRowData4: any
  columnDefs5: any[] = []
  displayRowData5: any[] = []
  columnDefs6: any[] = []
  displayRowData6: any[] = []
  rowClassRules: any
  gridApi: any
  defaultColDef: {}
  approvedItems: any
  errorList: string[] = [];
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  backupApprovedItems: any
  updatePPF: any
  specialistCode: any
  plantCode: any
  isdecisionCount = false
  decisionCount: any
  isdiscCount = false
  discCount: any
  ismflmsCount = false
  mflmsCount: any
  ispreCount = false
  preCount: any
  detail: any[] = []
  url: any
  decisionDetail: any
  discrepencyDetail: any
  mflmsDetail: any
  preDetail: any
  userRole: any
  rolesFromLocal: any
  userInfo: any
  loading = true
  spCode: any
  spCodeValue: any
  plant: any[] = [];
  spCodeValues: any;
  title: any;
  userInfoSubscriber: any
  pkgStartDate: any
  dateFormat = 'MM/dd/yyyy';
  format = 'en-US';
  timeStd = 'UTC';
  constructor(
    private readonly homeService: HomeService,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly state: ExportStateService,
    private readonly spinnerService: NgxUiLoaderService,
    private readonly constants: ConstantsService,
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.getRoles()
    const approvedItems = [
      'Send PPF to Supplier',
      'Packing Proposal',
      'Packaging Trial',
      'Final PPF Approval',
      'Trial Order',
      'Package Authorized',
    ]
    this.approvedItems = approvedItems

    this.columnDef1();
    this.columnDef2();
    this.columnDef3();

    const backupApprovedItems = [
      'Backup Define',
      'Backup Use',
      'Backup Reimbursement',
    ]
    this.backupApprovedItems = backupApprovedItems

    const updatePPF = ['Update PPF Approved']
    this.updatePPF = updatePPF

    this.columnDef4();
  }

  getRoles() {
    this.state.roleState.subscribe({
      next: (data: any) => {
        if (data) {
          if(this.constants.allInternal.includes(data[0])){
            this.spinnerService.start()
            this.userRole = data[0]
            this.rolesFromLocal = data
            const userRoles = data
            if (this.constants.allInternal.includes(this.userRole)) {
              this.getUserData(userRoles);
            }
          }else{
            this.router.navigate(['/']);
          } 
        }
        this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.errorList.push(_err.message);
        this.spinnerService.stop()
      },
    })
  }

  getUserData(userRoles:any[]) {
    if(this.constants.allInternal.includes(userRoles[0])){
      this.userInfoSubscriber = this.state.userInfoState.subscribe((data: any) => {
        if (data) {
          this.userInfo = data.info
          this.specialistCode = this.userInfo?.p_specialistcode
          sessionStorage.setItem(
            'specialistCode',
            JSON.stringify(this.specialistCode),
          )
          this.plantCode = this.userInfo?.p_plantcode;
          this.title = this.userInfo?.title;
          sessionStorage.setItem('plantCode', JSON.stringify(this.plantCode));
          this.pkgStartDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));

          if(this.specialistCode){
            this.loadPlant()
            this.loadNecessaryAppItems(this.specialistCode);
            this.loadUpdatePPF(this.specialistCode);
            this.loadBackUpStatus(this.specialistCode);
            this.loadTrackingStatus(this.specialistCode);
            if (this.plantCode) {
              this.loadDataLog(this.specialistCode, this.plantCode);
              this.loadDataTask(this.specialistCode, this.plantCode);
            }
          }
        }
      })
    }
  }

  ngOnDestroy(){
    this.userInfoSubscriber?.unsubscribe();
  }

  loadPlant() {
    const obj = {
      SpecialistCode: this.specialistCode,
      Type: 1,
    };
    this.homeService.loadAllPlants(obj).subscribe({
      next: (res: any) => {
        this.plant = res;
      },
    });
  }

  getParamValue(key: string, dataArray: { key: string; value: string }[]): string | undefined {
    const param = dataArray.find((item) => item.key === key);
    return param?.value;
  }

  navigateStep(url: any) {
    const queryparams = url.split('?')[1];
    const params = queryparams.split('&');
    let pair = null;
    const data: { key: any; value: any }[] = [];
    params.forEach((d: any) => {
      pair = d.split('=');
      data.push({ key: pair[0], value: pair[1] });
    });
    const reqParam = {
      plantcode: this.getParamValue('plant', data),
      partnumber: this.getParamValue('part', data),
      suppliercode: this.getParamValue('supplier', data),
      pkgstartdate: this.getParamValue('fromdate', data),
      ppfcode: '',
      ppfver: '',
    }
    sessionStorage.setItem('deptApprove', JSON.stringify(reqParam))
    this.router.navigate(['/tracking-list/send-ppf']);
  }

  updateUser(selectedItem: any) {
    if (selectedItem.taskcode === '201') {
      const obj = {
        authNumber: selectedItem.urlparameter.split("?auth=")[1],
        specialistCode: this.specialistCode,
        plantCode: this.plantCode,
        flag: '',
        mode: 2
      }
      sessionStorage.setItem('createRCAF', JSON.stringify(obj))
      this.router.navigateByUrl('/auth-list/create-rcaf');
    }
    else if (selectedItem.taskcode === '205') {
      this.router.navigate(['/tracking-list/trial-order']);
    }
    else if (selectedItem.taskcode === '501') {
      this.dialog.open(UpdateUserComponent,
        {
          data: {
            pageName: 'update', row: { specialistcode: selectedItem.detail },
            plants: this.plant, userInfo: this.userInfo
          }, width: '80%'
        })
    }
    else if (selectedItem.taskcode === '301') {
      this.router.navigate([`/internal/decision-list/${this.plantCode}/${selectedItem.detail}`]);
    }
    else if (selectedItem.taskcode === '401') {
      this.router.navigate([`/internal/discripency-list/${this.plantCode}/${selectedItem.detail}`]);
    } else if (selectedItem.taskcode === '402') {
      this.router.navigate(['/log-details', '1']);
    } else if (selectedItem.taskcode === '403') {
      this.router.navigate(['/log-details', '2']);
    } else if (selectedItem.taskcode === '101') {
      sessionStorage.setItem('step', '4')
      this.navigateStep(selectedItem.urlparameter);
    }
    else if (selectedItem.taskcode === '102') {
      sessionStorage.setItem('step', '7')
      this.navigateStep(selectedItem.urlparameter);
    }
    else if (selectedItem.taskcode === '103') {
      sessionStorage.setItem('step', '9')
      this.navigateStep(selectedItem.urlparameter);
    }
  }

  columnDef1() {
    this.columnDefs = [
      {
        headerName: 'APPROVED ITEMS',
        field: 'approvedItems',
        minWidth: 150,
        maxWidth: 170,
        pinned: 'left',
        cellRenderer: (params: any) => {
          const index = this.approvedItems[params.node?.rowIndex]
          return `<span>${index}</span>`
        }
      },
      {
        headerName: 'Delay',
        autoHeight: true,
        field: 'd',
        minWidth: 118,
        maxWidth: 138,
        unSortIcon: true,
        sortable: false,
        headerClass: 'headerDelayColor',
        cellRendererFramework: DelayGridComponent,
      },
      {
        headerName: 'Warn',
        autoHeight: true,
        field: 'w',
        minWidth: 118,
        maxWidth: 138,
        unSortIcon: true,
        sortable: false,
        headerClass: 'headerWarnColor',
        cellRendererFramework: WarnGridComponent,
      },
      {
        headerName: 'Good',
        autoHeight: true,
        field: 'g',
        minWidth: 118,
        maxWidth: 138,
        unSortIcon: true,
        sortable: false,
        headerClass: 'headerGoodColor',
        cellRendererFramework: GoodGridComponent,
      },
    ]
  }

  columnDef2() {
    this.columnDefs2 = [
      {
        headerName: 'NECESSARY APPROVED ITEMS',
        field: 'title',
        cellStyle: { color: '#2688DA', cursor: 'pointer' },
        cellRendererFramework: ApprovedItemsCellRenderer,
      },
    ]
  }

  columnDef3() {
    this.columnDefs3 = [
      {
        headerName: 'LOGS',
        field: 'logdetail',
        minWidth: 568,
        maxWidth: 568,
        cellRendererFramework: LogRendererComponent,
      },
    ]
  }

  columnDef4() {
    this.columnDefs5 = [
      {
        headerName: 'UPDATE PPF',
        field: 'updatePpf',
        pinned: 'left',
        minWidth: 300,
        maxWidth: 350,
        cellRenderer: (params: any) => {
          const index = this.updatePPF[params.node.rowIndex]
          return `<span>${index}</span>`
        },
      },
      {
        headerName: '',
        autoHeight: true,
        field: 'task',
        unSortIcon: true,
        sortable: false,
        minWidth: 280,
        maxWidth: 300,
        cellRendererFramework: UpdatePpfRendererComponent,
      },
    ]
  }

  close() {
    this.errorList = [];
  }

  loadDataLog(spCode: any, plCode: any) {
    this.loading = true
    const params = {
      SpecialistCode: spCode,
      PlantCode: plCode,
    }
    this.homeService.loadDataLog(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.displayRowData3 = res.result;
      },
      error: (_err: any) => {
        this.loading = false
        this.spinnerService.stop()
      },
    }
    )
  };

  loadDataTask(spCode: any, plCode: any) {
    this.loading = true
    const params = {
      SpecialistCode: spCode,
      PlantCode: plCode,
      Title: this.title,
    }
    this.homeService.loadDataTask(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.displayRowData6 = res.Result;
        this.decisionCountCheck(res.DecisionCount);
        this.descCountCheck(res.DiscCount);
        this.mflmsCountCheck(res.MFLMSCount);
        this.preCountCheck(res.PreCount);
        this.checkTaskCode();
      },
      error: (_err: any) => {
        this.loading = false
        this.spinnerService.stop()
      },
    }
    )
  };

  loadNecessaryAppItems(spCode: any) {
    this.loading = true
    const params = {
      SpecialistCode: spCode,
    }
    this.homeService.loadNecessaryAppItems(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.displayRowData2 = res.result;
      },
      error: (_err: any) => {
        this.loading = false
        this.spinnerService.stop()
      },
    }
    )
  };

  loadUpdatePPF(spCode: any) {
    this.loading = true;
    const params = {
      SpecialistCode: spCode,
      pkgstartdate: formatDate(this.pkgStartDate, this.dateFormat, this.format, this.timeStd)
    }
    this.homeService.loadUpdatePPF(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.displayRowData5 = res.result;
      },
      error: (_err: any) => {
        this.loading = false
        this.spinnerService.stop()
      },
    }
    )
  };

  loadBackUpStatus(spCode: any) {
    this.loading = true;
    const params = {
      SpecialistCode: spCode,
      pkgstartdate: formatDate(this.pkgStartDate, this.dateFormat, this.format, this.timeStd)
    }
    this.homeService.loadBackUpStatus(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.displayRowData4 = res.result[0];
      },
      error: (_err: any) => {
        this.loading = false;
        this.spinnerService.stop()
      },
    }
    )
  }

  loadTrackingStatus(spCode: any) {
    this.loading = true;
    const params = {
      SpecialistCode: spCode,
      pkgstartdate: formatDate(this.pkgStartDate, this.dateFormat, this.format, this.timeStd)
    }
    this.homeService.loadTrackingStatus(params).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.displayRowData = res;
      },
      error: (_err: any) => {
        this.loading = false;
        this.spinnerService.stop()
      },
    }
    )
  }

  discNavigate() {
    this.router.navigate([`discripency-list`])
  }

  checkTaskCode() {
    this.displayRowData6?.forEach((val: any, key: any) => {
      switch (val.taskcode) {
        case '101': {
          this.detail[key] = 'Pre.PPF was rejected.';
          break;
        }
        case '102': {
          this.detail[key] = 'Pkg trial was rejected.'
          break;
        }
        case '103': {
          this.detail[key] = 'Final PPF was rejected.'
          break;
        }
        case '201': {
          this.detail[key] = 'Authorizing package was rejected.'
          break;
        }
        case '202': {
          this.detail[key] = 'Moving package was rejected.'
          break;
        }
        case '203': {
          this.detail[key] = 'Disposing package was rejected.'
          break;
        }
        case '204': {
          this.detail[key] = 'Pkg maintenance was rejected.'
          break;
        }
        case '205': {
          this.detail[key] = 'Pkg trial was rejected.'
          break;
        }
        case '301': {
          this.detail[key] = 'There are data which you have to make decision.'
          break;
        }
        case '401': {
          this.detail[key] = 'There are discrepancy data.'
          break;
        }
        case '402': {
          this.detail[key] = 'There are discrepancy data of M/F and LMS.'
          break;
        }
        case '403': {
          this.detail[key] = 'There are discrepancy data of Preliminary.'
          break;
        }
        case '501': {
          this.detail[key] = 'There is a new user. You have to do valid on.'
          break;
        }
      }
    })
  }

  preCountCheck(PreCount: any) {
    if (Number(PreCount) === 0) {
      this.ispreCount = false
    } else {
      this.ispreCount = true
      this.preCount = PreCount
      this.preDetail = 'There are discrepancy data of Preliminary.'
    }
  }

  mflmsCountCheck(MFLMSCount: any) {
    if (Number(MFLMSCount) === 0) {
      this.ismflmsCount = false
    } else {
      this.ismflmsCount = true
      this.mflmsCount = MFLMSCount
      this.mflmsDetail = 'There are discrepancy data of M/F and LMS.'
    }
  }

  descCountCheck(count: any) {
    if (Number(count) === 0) {
      this.isdiscCount = false
    } else {
      this.isdiscCount = true
      this.discCount = count
      this.discrepencyDetail = 'There are discrepancy data.'
    }
  }

  decisionCountCheck(count: any) {
    if (Number(count) === 0) {
      this.isdecisionCount = false
    } else {
      this.isdecisionCount = true
      this.decisionCount = count
      this.decisionDetail = 'There are data which you have to make decision.'
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api
  }

  onGridReady2(params: any) {
    this.gridApi = params.api
  }

  onGridReady3(params: any) {
    this.gridApi = params.api
  }

  onGridReady4(params: any) {
    this.gridApi = params.api
  }

  onGridReady5(params: any) {
    this.gridApi = params.api
  }

  onGridReady6(params: any) {
    this.gridApi = params.api
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  navigate(param: any) {
    if (param === 'define') {
      this.router?.navigate(['/tracking-list', this.plantCode, this.specialistCode, 1, 1, 1, 20, 1])
    }
    if (param === 'use') {
      this.router?.navigateByUrl(
        `/backup-auth-list/${this.specialistCode}/1`,
      )
    }
    if (param === 'reimburse') {
      this.router?.navigateByUrl(
        `/backup-auth-list/${this.specialistCode}/4`,
      )
    }
  }

  help() {
    this.router.navigate(['./master-help', 1, 'Main Menu', 3])
  }
  openDialog(pageName: string, _data?: any) {
    this.dialog.open(UpdateUserComponent, { data: { pageName: pageName,plants:this.plant}, width: '80%' });
   }
}
