<div class="new-package" [formGroup]="newPackageForm">
    <h2 class="px-3">New Package</h2>
    <div class="input-fields d-flex flex-wrap align-items-start px-3">
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex d-flex flex-wrap flex-column">
            <div class="col-12 d-flex ">
                <label for="pkgcode" class="col-3">Code </label>
                <input type="text" class="col-9" id="pkgcode" formControlName="pkgcode" maxlength="15"
                    (keypress)="omitSpecialChars($event)" (paste)="onPaste($event)" />
            </div>
            <div class="col-12 mt-1" *ngIf="['2','3'].includes(rowNumber)">
                <span>(Leave empty for default PEND code)</span>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="group-code" class="col-3">Group Code
                <small *ngIf="isRequired('groupcode')">*</small>
            </label>
            <input type="text" class="col-9" id="group-code" formControlName="groupcode" maxlength="12" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="type" class="col-3">Type
                <small *ngIf="isRequired('pkgtypecode')">*</small>
            </label>
            <mat-select formControlName="pkgtypecode" class="mat-sel pt-2 col-6" disableOptionCentering>
                <mat-option *ngFor="let x of type" [value]="x.packagetypecode">{{x.packagetype}}</mat-option>
            </mat-select>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="description" class="col-3">Description
                <small *ngIf="isRequired('description')">*</small>
            </label>
            <input type="text" class="col-9" id="description" formControlName="description" maxlength="24" /><br />
            <div *ngIf="isValid['description']?.invalid && (isValid['description']?.dirty || isValid['description']?.touched)">
                <div *ngIf="isValid['description'].errors?.['maxlength']  " class="errorValid"></div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="dunnageAttachment" class="col-3">Dunnage Attachment
                <small *ngIf="isRequired('dunnattachmethod')">*</small>
            </label>
            <mat-select formControlName="dunnattachmethod" class="mat-sel pt-2 col-6" disableOptionCentering>
                <mat-option value="1">Rivets</mat-option>
                <mat-option value="2">Velcro</mat-option>
                <mat-option value="3">Glue</mat-option>
                <mat-option value="4">Bolts</mat-option>
                <mat-option value="5">Other-Totes</mat-option>
                <mat-option value="6">Other-Rack</mat-option>
            </mat-select>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="weight" class="col-3">Weight(kg)
                <small *ngIf="isRequired('weight')">*</small>
            </label>
            <input type="text" class="col-9" id="weight" formControlName="weight" maxlength="5" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="material" class="col-3">Material
                <small *ngIf="isRequired('materialcode')">*</small>
            </label>
            <mat-select formControlName="materialcode" id="material" class="mat-sel pt-2 col-9" disableOptionCentering>
                <mat-option *ngFor="let x of material" [value]="x.materialcode">{{x.material}}</mat-option>
            </mat-select>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="nesting" class="col-3">Nesting(mm)
                <small *ngIf="isRequired('nesting')">*</small>
            </label>
            <input type="text" class="col-9" id="nesting" maxlength="5" formControlName="nesting"
                (change)="changeMM($event, 'nesting')" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="return-height" class="col-3">Return height(mm)
                <small *ngIf="isRequired('returnheight')">*</small>
            </label>
            <input type="text" class="col-9" id="return-height" maxlength="5" formControlName="returnheight"
                (change)="changeMM($event, 'returnheight')" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="s/n" class="col-3">S/N
                <small *ngIf="isRequired('standard')">*</small>
            </label>
            <mat-select formControlName="standard" id="s/n" class="mat-sel pt-2 col-6" disableOptionCentering>
                <mat-option value="S">Standard</mat-option>
                <mat-option value="N">Non Standard</mat-option>
            </mat-select>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="r/e" class="col-3">R/E
                <small *ngIf="isRequired('returnable')">*</small>
            </label>
            <mat-select formControlName="returnable" id="re" class="mat-sel pt-2 col-6" disableOptionCentering>
                <mat-option value="RET">Returnable</mat-option>
                <mat-option value="EXP">Expendable</mat-option>
            </mat-select>
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="qtyCount/Layer" class="col-3">Qty Count/ Layer
                <small *ngIf="isRequired('qtycontlayer')">*</small>
            </label>
            <input type="text" class="col-9" id="qtyCount/Layer" formControlName="qtycontlayer" maxlength="2" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="qtyCount/Pallet" class="col-3">Qty Count/Pallet
                <small *ngIf="isRequired('qtylayerpallet')">*</small>
            </label>
            <input type="text" class="col-9" id="qtyCount/Pallet" formControlName="qtylayerpallet" maxlength="2" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="qtyHolder" class="col-3">Qty Holder
                <small *ngIf="isRequired('qtykholder')">*</small>
            </label>
            <input type="text" class="col-9" id="qtyHolder" formControlName="qtykholder" maxlength="2" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="qtyOfLabel" class="col-3">Qty of Label
                <small *ngIf="isRequired('qtylabel')">*</small>
            </label>
            <input type="text" class="col-9" id="qtyOfLabel" formControlName="qtylabel" maxlength="10" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="costOfLabel" class="col-3">Cost of Label
                <small *ngIf="isRequired('costlabel')">*</small>
            </label>
            <input type="text" class="col-9" id="costOfLabel" formControlName="costlabel" maxlength="10" />
        </div>
        <div class="col-lg-3 col-md-6 col-12 vpacs-flex">
            <label for="leadTime" class="col-3">Lead Time
                <small *ngIf="isRequired('leadtime')">*</small>
            </label>
            <input type="text" class="col-9" id="leadTime" formControlName="leadtime" maxlength="10" />
        </div>
        <div class="col-lg-4 col-md-6 col-12 floating">
            <span>OD</span>
            <small *ngIf="isRequired('outerlength')">*</small>
            <label for="length">Length(mm)</label>
            <input type="text" id="length" class="col-3 mx-2" maxlength="5" formControlName="outerlength"
                (change)="changeMM($event, 'outerlength')" />
            <label for="width">Width(mm)</label>
            <input type="text" id="width" class="col-3 mx-2" maxlength="5" formControlName="outerwidth"
                (change)="changeMM($event, 'outerwidth')" />
            <label for="height">Height(mm)</label>
            <input type="text" id="height" class="col-3 mx-2" maxlength="5" formControlName="outerheight"
                (change)="changeMM($event, 'outerheight')" />
        </div>
        <div class="col-lg-4 col-md-6 col-12 floating ">
            <span>ID</span>
            <small *ngIf="isRequired('innerlength')">*</small>
            <label for="length">Length(mm)</label>
            <input type="text" id="length" class="col-3 mx-2" maxlength="5" formControlName="innerlength"
                (change)="changeMM($event, 'innerlength')" />
            <label for="width">Width(mm)</label>
            <input type="text" id="width" class="col-3 mx-2" maxlength="5" formControlName="innerwidth"
                (change)="changeMM($event, 'innerwidth')" />
            <label for="height">Height(mm)</label>
            <input type="text" id="height" class="col-3 mx-2" maxlength="5" formControlName="innerheight"
                (change)="changeMM($event, 'innerheight')" />
        </div>
        <div class="vpacs-flex col-12">
            <span class="col-1">Drawing</span>
            <div>
                <label for="draw" class="vpacs-btn-table">CHOOSE FILE</label>
                <input type="file" id="draw" style="display: none;" (change)="handleUpload($event, 'drawing')" />
            </div>
            <span>{{newPackageForm.controls.drawFileName.value || noFile}}</span>
        </div>
        <span>{{drawError}}</span>
        <div class="vpacs-flex col-12">
            <span class="col-1">Picture</span>
            <div>
                <label for="phot" class="vpacs-btn-table">CHOOSE FILE</label>
                <input type="file" id="phot" style="display: none;" (change)="handleUpload($event, 'picture')" />
            </div>
            <span>{{newPackageForm.controls.photFileName.value || noFile}}</span>
        </div>
        <span>{{photError}}</span>
    </div>
    <div class="footer-new mt-3 fixed-bottom d-flex">
        <div class="mx-3 cancel" (click)="onCancel()">Cancel</div>
        <button class="black-button mx-3" (click)="addNewPackage()">Ok</button>
    </div>
</div>