import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DocumentationService } from 'src/app/internal-vpacs/services-internal/standard-reports/documentation.service'
import { ExportStateService } from 'src/app/services/export-state.service'
import { Router } from '@angular/router'
import { NgxUiLoaderService } from 'ngx-ui-loader'
@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
})
export class AddDocumentComponent implements OnInit {
  fileType: any[] = []
  fileNameRequired = false
  fileExtensionValid = true // Initialize as valid
  fileDocForm: FormGroup = new FormGroup({
    file: new FormControl(''),
    fileName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(50)])),
    description: new FormControl(''),
  })
  base64File: any = ''
  errorMessage1 = ''
  errorMessage = ''
  pageName = ''
  userInfo: any
  pName: any
  param: any
  fileName: any;
  allowedExtensions = [
    '.pdf',
    '.xlsx',
    '.doc',
    '.docx',
    '.ppt',
    '.csv',
    '.pptx'
  ]
  fileSizeValid = true;
  contentType: any
  preurl: any
  uploadFile: any

  constructor(
    private readonly documentationService: DocumentationService,
    private readonly dialogRef: MatDialogRef<AddDocumentComponent>,
    private readonly state: ExportStateService,
    public router: Router,
    public spinner : NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          if (res.info.p_specialistcode) {
            this.userInfo = res.info.p_specialistcode
          }
        }
      },
    })
    this.pageName = this.data?.pageName
    this.param = this.data?.res
    if (this.pageName === 'update') {
      this.fileName = this.data.res.fileName
      this.fileType = ['xlsx', 'doc', 'docx', 'ppt', 'csv', 'pdf', 'pptx']
    }
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    this.uploadFile = event.target.files[0];
    this.contentType = file.type
    const splitExtension = file.name.lastIndexOf('.');
    const fileType = file.name.substring(splitExtension);
    if(this.allowedExtensions.includes(fileType)){
      this.fileExtensionValid = true;
      if(file.name){
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const base64:any = reader.result;
            this.base64File = reader.result;
            this.fileSizeValid = true;
            this.fileDocForm.controls['fileName'].setValue(file.name);
        }
      }
    }else{
      this.fileExtensionValid = false;
      this.fileDocForm.controls['fileName'].setValue(null);
    }
  }

  isValidFileExtension(fileName: string): boolean {
    const allowedExtensions = [
      '.pdf',
      '.xlsx',
      '.doc',
      '.docx',
      '.ppt',
      '.csv',
      '.pptx'
    ]
    const fileExtension = fileName.split('.').pop()
    return allowedExtensions.includes(`.${fileExtension}`)
  }

  addDocument() {
    this.fileDocForm.markAllAsTouched();
    if(this.fileDocForm.valid){
      const params = {
        file: { data: this.base64File.split(',')[1] },
        fileName: this.fileDocForm.controls['fileName'].value,
        user: this.userInfo,
        desc: this.fileDocForm.controls['description'].value,
      }
      this.pName = params;
      this.spinner.start();
      this.documentationService.fileUpload(params).subscribe({
        next: (res: any) => {
          if (res.message === 'Duplicate Data') {
            this.pageName = 'update'
          } else {
            this.dialogData('add', '')
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      })
    }
  }

  addDocumentPresigned() {
    this.fileDocForm.markAllAsTouched();
    if (this.fileDocForm.valid) {
      const params = {
        contentType: this.contentType,
        fileName: this.fileDocForm.controls['fileName'].value,
        user: this.userInfo,
        desc: this.fileDocForm.controls['description'].value,
      }
      this.pName = params;
      this.spinner.start();
      this.documentationService.getPresingedurl(params).subscribe({
        next: (res: any) => {
          if (res.message === 'Presinged url') {
            this.preurl = res.body;
            this.putFileintoS32(res).then(() => {
              this.dialogData('add', '')
              this.spinner.stop();
            }).catch((err) => {
              console.error("Upload failed:", err);
            });
          } else if (res.message === 'Duplicate Data') {
            this.pageName = 'update'
          } else {
            this.dialogData('error', '')
          }
          this.spinner.stop();
        },
        error: (_err:any) =>{
          this.spinner.stop();
        }
      })
    }
  }

  updataFilePresigned() {
    this.fileDocForm.markAllAsTouched();
    if (this.fileDocForm.valid) {
      const params = {
        contentType: this.contentType,
        fileName: this.fileDocForm.controls['fileName'].value,
        user: this.userInfo,
        desc: this.fileDocForm.controls['description'].value,
      }
      this.pName = params;
      this.spinner.start();
      this.documentationService.updateData(params).subscribe({
        next: (res: any) => {
          if (res.message === 'Presinged url') {
            this.preurl = res.body;
            this.putFileintoS32(res).then(() => {
              this.dialogData('add', '')
              this.spinner.stop();
            }).catch((err) => {
              console.error("Upload failed:", err);
            });
          } else {
            this.dialogData('error', '')
          }
          this.spinner.stop();
        },
        error: (_err:any) =>{
          this.spinner.stop();
        }
      })
    }
  }

  async putFileintoS32(metadata : any) {
    this.spinner.start();
    const file = this.uploadFile
    const uploadResponse = await fetch(this.preurl, {
      method: 'PUT',
      body: file, 
      headers: {
        'Content-Type': file.type, 
      },
    });
  }


  updataFile() {
    const params = this.pName;
    this.spinner.start();
    this.documentationService.updateData(params).subscribe({
      next: (_res: any) => {
        if(_res.status){
          this.dialogData('add', params);
        }
        this.spinner.stop();
      },
      error : (_err:any) =>{
        this.spinner.stop();
      }
    })
  }

  closeDialog() {
    this.dialogRef.close()
  }

  dialogData(params: string, pNam: string) {
    this.dialogRef.close({ data: { type: params, name: pNam } })
  }

  help() {
    this.dialogRef.close()
    this.router.navigate(['./master-help', 6, 'Standard Report', 301])
  }
}
