<div class="popup-container" [formGroup]="fileDocForm" *ngIf="pageName === 'add' ">
    <div class="d-flex justify-content-between header text-middle align-items-center">
        <div class="page-title">Add Document</div>
        <button class="help-btn" (click)="help()">Help</button>
    </div>
    <div>
        <div class="d-flex flex-wrap align-items-center p-2 col-12">
            <label class="fileHead">File's extension must be , .xlsx , .doc , .docx , .ppt , .csv , .pdf, .pptx</label>
            <span class="col-12 my-1">File<small>*</small></span>
            <label for="documentation" class="vpacs-btn-table col-4" (change)="handleUpload($event)">
                CHOOSE FILE
                <input type="file" id="documentation" style="display: none;"
                    accept=".pdf, .xlsx, .doc, .docx, .ppt, .csv, .pptx" size="60" />
            </label>
            <small *ngIf="!fileExtensionValid">Please upload a valid file</small>
            <span class="col-12" *ngIf="fileDocForm.get('fileName')?.valid">{{fileDocForm.controls['fileName'].value}}</span>
            <div *ngIf="(fileDocForm.get('fileName')?.invalid)" class="col-12">
                <small *ngIf="fileDocForm.get('fileName')?.hasError('maxlength')">
                    Filename cannot be more than 50 characters.
                </small>
                <small *ngIf="(fileDocForm.get('fileName')?.touched && fileDocForm.get('fileName')?.hasError('required'))">
                    File is required.
                </small>
            </div>
            <span class="col-12 my-1">Description</span>
            <textarea class="col-12" rows="4" formControlName="description"></textarea>
        </div>
    </div>
    <div class="foot">
        <div mat-dialog-close class="mx-3 text-primary">CANCEL</div>
        <button class="black-button mx-3" (click)="addDocumentPresigned()">Add</button>
    </div>
    <button class="close black-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="popup-container" *ngIf="pageName === 'delete' ">
    <h2 mat-dialog-title class="p-2 shadow-sm">Delete Confirmation</h2>
    <div mat-dialog-content>
        <p class="p-2">Are you sure that you want to delete this file?</p>
    </div>
    <div class="d-flex justify-content-end col-12 align-items-center p-2">
        <div mat-dialog-close class="text-primary mx-3 ">No</div>
        <button class="black-button mx-3" style="height: 30px;" (click)="dialogData('delete','data')">Yes</button>
    </div>
</div>
<div class="popup-container" *ngIf="pageName === 'update' ">
    <h2 mat-dialog-title class="p-2 shadow-sm">Add Document confirmation</h2>
    <span class="col-12 my-2 mx-2">File-</span>
    <span class="col-8">{{fileDocForm.controls['fileName'].value}}</span>
    <div mat-dialog-content>
        <p class="p-2">Already exists,Would you like to replace the existing file?</p>
    </div>
    <div class="d-flex justify-content-end col-12 align-items-center p-2">
        <div mat-dialog-close class="text-primary mx-3 ">No</div>
        <button class="black-button mx-3" style="height: 30px;" (click)="updataFilePresigned()">Yes</button>
    </div>
</div>