export const environment = {
	production: false,
	EXTERNAL: 'https://external-int.api.dev.vpacs.cloud.toyota.com/api/', // external pg
	INTERNAL: 'https://internal.api.dev.vpacs.cloud.toyota.com/api/', // internal vpacs	
	//MASTERCOST:'https://mastercost.api.dev.vpacs.cloud.toyota.com/api/',// master cost
	// MASTERCOST:'https://mastercost.api.vpacs.cloud.toyota.com/prod/',// master cost
	MASTERCOST: 'https://3vszrvio4i.execute-api.us-west-2.amazonaws.com/dev/api/',
	//   MASTERCOST:'https://mastercost.api.vpacs.cloud.toyota.com/dev/api/',
	TRACKING: 'https://tracking.api.dev.vpacs.cloud.toyota.com/api/', // tracking
	TENANT_ID: 'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',
	CLIENT_ID: '777ab753-7eb1-4e33-8ff9-b39d644f32b3',
	REDIRECT_URL: 'https://dev.vpacs.cloud.toyota.com',
	VPACSHOMEURL: 'https://dev.vpacs.cloud.toyota.com',
	API_CALL: 'cIJVafXSx68BSZ5WfvF4U6DQ30uJIKmM2mgCnEL8'
};
