import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConstantsService } from 'src/app/services/constant.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  URL = this.constantsService.MASTERCOST ;
  constructor(private readonly http:HttpClient, public constantsService: ConstantsService) { }

  loadDocData() : Observable<any>{
    return this.http.get(this.URL+'documentation/onLoad',{headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  deleteFile(body:any):Observable<any>{

    return this.http.post(this.URL+'documentation/delete',body, {headers: this.constantsService.httpOptions,responseType: 'json' }).pipe(catchError(this.handleError))
  }

  downloadFile(id:any, filename:any):any{
    const download = `documentation/downloadFile?id=${id}&fileName=${filename}`
		return this.http.get(this.URL+download,{ observe: 'response', responseType: 'text', headers: this.constantsService.httpOptions} ).pipe(catchError(this.handleError))
  }

  fileUpload(body:any):Observable<any>{

    return this.http.post(this.URL+'documentation/addDoc',body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  getPresingedurl(body:any):Observable<any>{

    return this.http.post(this.URL+'documentation/getPresignedUrl',body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }

  putfileintoPresingedurl(body:any,url:any):Observable<any>{

    const headers = { 'Content-Type': body.type }; 
    return this.http.put(url,body, {headers: headers}).pipe(catchError(this.handleError))
  }

  updateData(body:any):Observable<any>{
    return this.http.post(this.URL+'documentation/updateDoc',body, {headers: this.constantsService.httpOptions}).pipe(catchError(this.handleError))
  }
  private handleError(error: HttpErrorResponse) {
		return throwError(()=> error || "Server Error");
	}
}
