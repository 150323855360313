import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { PpfServiceInternal } from '../../services-internal/ppf.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PPFSelectRender } from '../../internal-Vpacs-gridrender/ppf/ppf-select-render';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-pkg-fab',
  templateUrl: './select-pkg-fab.component.html',
  styleUrls: ['./select-pkg-fab.component.scss']
})
export class SelectPkgFabComponent implements OnInit {
  @ViewChild('select', { static: true }) select: MatSelect | undefined;
  allSelected = true;
  dropdownValues: any[] = []
  dropdownList: any[] = [
    { group: 1, viewValue: 'B' },
    { group: 1, viewValue: 'D' },
    { group: 1, viewValue: 'K' },
    { group: 1, viewValue: 'N' },
    { group: 1, viewValue: 'R' },
    { group: 1, viewValue: 'S' },
    { group: 1, viewValue: 'T' },
    { group: 1, viewValue: 'V' },
    { group: 1, viewValue: 'X' },
    { group: 1, viewValue: 'Z' },
    { group: 1, viewValue: 'C' },
    { group: 1, viewValue: 'F' },

    { group: 2, viewValue: '0' },
    { group: 2, viewValue: '1' },
    { group: 2, viewValue: '2' },
    { group: 2, viewValue: '3' },
    { group: 2, viewValue: '4' },
    { group: 2, viewValue: '5' },
    { group: 2, viewValue: '6' },
    { group: 2, viewValue: '7' },
    { group: 2, viewValue: '8' },
    { group: 2, viewValue: '9' },

    { group: 4, viewValue: 'L' },
    { group: 5, viewValue: 'P' },
    { group: 6, viewValue: 'W' },
    { group: 6, viewValue: 'Y' },

    { group: 7, viewValue: 'E' },
    { group: 7, viewValue: 'H' },
    { group: 8, viewValue: 'A' },
    { group: 8, viewValue: 'O' },
  ];
  selectForm = new FormGroup({
    code: new FormControl(''),
    alpha: new FormControl(''),
    name: new FormControl(''),
    commodity: new FormControl([]),
  })
  //ag-grid
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: any;
  gridOptions !: GridOptions;
  icons !: {};
  rowHeight = 40;
  totalPage = 0;
  public overlayNoRowsTemplate =
    `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  cPage = 1;
  tPage = 0;
  rpPage = 50;
  totalRecords = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly service: PpfServiceInternal,
    private readonly spinner : NgxUiLoaderService,
    public dialogRef: MatDialogRef<SelectPkgFabComponent>,private readonly router:Router,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data.row) {
      this.dropdownValues = this.dropdownList.filter((x: any) => {
        return x.group === (this.data.row === 3  ? 2 : this.data.row);
      })
    }
    setTimeout(() => {
      this.toggleAllSelection();
      this.apiCall();
    });
    this.getColumnDefs();
  }

  getColumnDefs(){
    this.columnDefs = [
      {
        headerName: "", 
        field: "", 
        width: 60,
        cellRendererFramework: PPFSelectRender,
        cellRendererParams: {
          clicked: (field: any) => {
            this.btnSelected(field);
          },
        },
      },
      {
        headerName: "CODE", 
        field: "pkgsupcode", 
        width: 90,
      },
      {
        headerName: "ALPHA CODE", 
        field: "alphacode", 
        width: 60,
      },
      {
        headerName: "NAME", 
        field: "name", 
        minWidth: 60,
        floatingFilter: false,
        sortable: false
      },
      {
        headerName: "COMMODITY", 
        field: "commodity",
        minWidth: 60,
        floatingFilter: false,
        sortable: false
      },
      {
        headerName: "5 DIGIT", 
        field: "supcode", 
        width: 60,
        floatingFilter: false,
        sortable: false
      },
      {
        headerName: "COST", 
        field: "cost",
        width: 60,
        floatingFilter: false,
        sortable: false
      },
   ] 
  }

  search() {
    this.apiCall();
  }

  apiCall() {
    this.spinner.start();
    const body = {
      "PackageSupplierCode": this.selectForm.controls.code.value?.toUpperCase(),
      "AlphaCode": this.selectForm.controls.alpha.value?.toUpperCase(),
      "Name": this.selectForm.controls.name.value,
      "url_location": this.data.row.toString(),
      "Commodity": this.selectForm.controls.commodity.value?.length === this.dropdownValues.length ? "" : this.selectForm.controls.commodity.value,
      "sort" : "",
      "url_pkgCode": this.data?.packageCode,
      "userPlantCode": this.data.userInfo?.info?.plantcode
    }
    this.service.selectPkgFab(body).subscribe({
      next: (res: any) => {
        this.displayRowData = res;
        this.rowCount = this.displayRowData.length;
        this.totalRecords = res.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.displayRowData = [];
        this.rowCount = 0;
        this.spinner.stop();
      }
    })
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select?.options.forEach((item: any) => item.select());
    } else {
      this.select?.options.forEach((item: any) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select?.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage)
  }

  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
  }

  sizeToFit() { this.gridApi?.sizeColumnsToFit() }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  btnSelected(params: any) {
    this.dialogRef.close({ data: {rowData : params, row : this.data.row} });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  help(){
    this.dialogRef.close()
    this.router.navigate(['./master-help',2,"Tracking",57])
 }
}
