import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { PpfService } from 'src/app/services/ppf/ppf.service';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'app-new-package',
  templateUrl: './new-package.component.html',
  styleUrls: ['./new-package.component.scss']
})
export class NewPackageComponent implements OnInit,OnDestroy {

  public newPackageForm:any ;
  public rowNumber:any ;
  protected type: any [] = [];
  protected material: any [] = [];
  protected validationArray!: { colreq: any[]; coldis: any[]; };
  public partNumber: any;
  public modelCode: any;
  drawError = '';
  photError = '';
  noFile = 'No Files Selected';
  userinfo:any;
  userInfoSubscriber: any;

  constructor(
    private readonly globalState:GlobalStateService, 
    public ppfService:PpfService,
    public location:Location,
    public message: MatSnackBar,private readonly constants:ConstantsService,private readonly state:ExportStateService) {
      this.newPackageForm = new FormGroup(
        {
          pkgcode: new FormControl(),
          groupcode: new FormControl(),
          pkgtypecode: new FormControl(),
          description: new FormControl(),
          dunnattachmethod: new FormControl(),
          weight: new FormControl(),
          materialcode: new FormControl(),
          nesting: new FormControl(),
          returnheight: new FormControl(),
          standard: new FormControl('S'),
          returnable: new FormControl('RET'),
          qtycontlayer: new FormControl(),
          qtylayerpallet: new FormControl(),
          qtykholder: new FormControl(),
          qtylabel: new FormControl(),
          costlabel: new FormControl(),
          leadtime: new FormControl(),
          outerlength: new FormControl(),
          outerwidth: new FormControl(),
          outerheight: new FormControl(),
          innerlength: new FormControl(),
          innerwidth: new FormControl(),
          innerheight: new FormControl(),
          draw: new FormControl(),
          phot: new FormControl(),
          photFileName: new FormControl(),
          drawFileName: new FormControl()
        }
      )    
     }

  ngOnInit(): void {
    this.rowNumber = sessionStorage.getItem('rowNumber');
    this.drawError = this.constants.newPackageDrawErrorFile;
    this.photError = this.constants.newPackagePhotoErrorFile;
    this.getUserInfo();
  }

  getUserInfo(){
    this.userInfoSubscriber = this.state.userInfoState.subscribe({
      next : (res:any) =>{
        if(Array.isArray(res.info)){
          this.userinfo = res?.info[0];
          this.callAPI();
        }
      },
      error : (err:any) => {
        this.openMessageBox(err.message || 'Unable to fetch data now','error');
      }
    })
  }

  callAPI(){
    this.ppfService.newPackageOnLoad(this.rowNumber).subscribe((res:any)=>{
      this.type = res.pkgType
      this.material = res.material
      this.validationArray = this.setValidation(res.compLevel[0].inputitem);
      this.setMandatory(this.validationArray)
      this.setDisabled(this.validationArray)
    })
  }

  setValidation(data:any){
    const colreq:any[]=[];
    const coldis:any[]=[];
    for(const element of data) {
      if(element === "0") {
        colreq.push("");
        coldis.push(true);
      } else if(element === "1") {
        colreq.push("");
        coldis.push(false);
      } else if(element === "2") {
        colreq.push("(*)");
        coldis.push(false);
      }	
    }
    return({colreq:colreq,coldis:coldis})
  }

  isRequired(fieldName:string){
    const validator = this.newPackageForm.get(fieldName);
    const req = validator.hasValidator(Validators.required);
    if (req) {
      return true;
    }else{
      return false;
    }
  }
  
  setMandatory(values:any){
    this.newPackageForm.get('pkgtypecode').addValidators([Validators.required]);
    this.newPackageForm.controls.pkgtypecode.updateValueAndValidity();
    this.newPackageForm.get('dunnattachmethod').addValidators([Validators.required]);
    this.newPackageForm.controls.dunnattachmethod.updateValueAndValidity();
    this.newPackageForm.get('description')?.setValidators([this.setvalidation('description')]);

    if(values.colreq[0]){
      this.newPackageForm.get('outerlength').addValidators([Validators.required]);
      this.newPackageForm.get('outerwidth').addValidators([Validators.required]);
      this.newPackageForm.get('outerheight').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('outerlength').addValidators([Validators.nullValidator]);
      this.newPackageForm.get('outerwidth').addValidators([Validators.nullValidator]);
      this.newPackageForm.get('outerheight').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.outerlength.updateValueAndValidity();
    this.newPackageForm.controls.outerwidth.updateValueAndValidity();
    this.newPackageForm.controls.outerheight.updateValueAndValidity();

    if(values.colreq[1]){
      this.newPackageForm.get('innerlength').addValidators([Validators.required]);
      this.newPackageForm.get('innerwidth').addValidators([Validators.required]);
      this.newPackageForm.get('innerheight').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('innerlength').addValidators([Validators.nullValidator]);
      this.newPackageForm.get('innerwidth').addValidators([Validators.nullValidator]);
      this.newPackageForm.get('innerheight').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.innerlength.updateValueAndValidity()
    this.newPackageForm.controls.innerwidth.updateValueAndValidity()
    this.newPackageForm.controls.innerheight.updateValueAndValidity()

    if(values.colreq[2]){
      this.newPackageForm.get('weight').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('weight').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.weight.updateValueAndValidity();

    if(values.colreq[3]){
      this.newPackageForm.get('materialcode').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('materialcode').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.materialcode.updateValueAndValidity()

    if(values.colreq[4]){
      this.newPackageForm.get('nesting').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('nesting').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.nesting.updateValueAndValidity()

    if(values.colreq[5]){
      this.newPackageForm.get('returnheight').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('returnheight').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.returnheight.updateValueAndValidity()

    if(values.colreq[6]){
      this.newPackageForm.get('standard').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('standard').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.standard.updateValueAndValidity()

    if(values.colreq[7]){
      this.newPackageForm.get('returnable').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('returnable').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.returnable.updateValueAndValidity()

    if(values.colreq[8]){
      this.newPackageForm.get('qtycontlayer').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('qtycontlayer').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.qtycontlayer.updateValueAndValidity()

    if(values.colreq[9]){
      this.newPackageForm.get('qtylayerpallet').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('qtylayerpallet').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.qtylayerpallet.updateValueAndValidity()

    if(values.colreq[10]){
      this.newPackageForm.get('qtykholder').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('qtykholder').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.qtykholder.updateValueAndValidity()

    if(values.colreq[11]){
      this.newPackageForm.get('qtylabel').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('qtylabel').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.qtylabel.updateValueAndValidity()

    if(values.colreq[12]){
      this.newPackageForm.get('costlabel').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('costlabel').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.costlabel.updateValueAndValidity()

    if(values.colreq[13]){
      this.newPackageForm.get('leadtime').addValidators([Validators.required]);
    }else{
      this.newPackageForm.get('leadtime').addValidators([Validators.nullValidator]);
    }
    this.newPackageForm.controls.leadtime.updateValueAndValidity()
  }

  get isValid() {
    return this.newPackageForm.controls;
  }

  setvalidation(field: any) {
    if (field === 'description') {
      return Validators.maxLength(20)
    }
    return Validators.nullValidator
  }

  setDisabled(values:any){
    if(values.coldis[0]){
      this.newPackageForm.get('outerlength')?.disable();
      this.newPackageForm.get('outerwidth')?.disable();
      this.newPackageForm.get('outerheight')?.disable();
    }
    if(values.coldis[1]){
      this.newPackageForm.get('innerlength')?.disable();
      this.newPackageForm.get('innerwidth')?.disable();
      this.newPackageForm.get('innerheight')?.disable();
    }
    if(values.coldis[2]){
      this.newPackageForm.get('weight')?.disable();
    }
    if(values.coldis[3]){
      this.newPackageForm.get('materialcode')?.disable();
    }
    if(values.coldis[4]){
      this.newPackageForm.get('nesting')?.disable();
    }
    if(values.coldis[5]){
      this.newPackageForm.get('returnheight')?.disable();
    }
    if(values.coldis[6]){
      this.newPackageForm.get('standard')?.disable();
    }
    if(values.coldis[7]){
      this.newPackageForm.get('returnable')?.disable();
    }
    if(values.coldis[8]){
      this.newPackageForm.get('qtycontlayer')?.disable();
    }
    if(values.coldis[9]){
      this.newPackageForm.get('qtylayerpallet')?.disable();
    }
    if(values.coldis[10]){
      this.newPackageForm.get('qtykholder')?.disable();
    }
    if(values.coldis[11]){
      this.newPackageForm.get('qtylabel')?.disable();
    }
    if(values.coldis[12]){
      this.newPackageForm.get('costoflabel')?.disable();
    }
    if(values.coldis[13]){
      this.newPackageForm.get('leadtime')?.disable();
    }
    if(values.coldis[14]){
      this.newPackageForm.get('dunnattachmethod')?.disable();
      this.newPackageForm.get('dunnattachmethod').clearValidators();
      this.newPackageForm.controls.dunnattachmethod.updateValueAndValidity();
    }
  }

  handleUpload(event:any, btnType:any) {
    const file = event.target.files[0];
    const contentType2 = file.name.split('.')[1]; //Get file type(Extension)
    const contentType = contentType2.toLowerCase(); //Get file type(Extension)
    const reader:any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Image: any = reader.result
      //get file size
      const fileSizeInBytes = base64Image.length * 0.75; //Get file size in bytes
      const fileSizeInKb = fileSizeInBytes / 1024; //Get file size in kilobytes
      if(btnType === 'drawing' ){
        if (this.constants.newPackageDrawAllowedTypes.includes(contentType) && fileSizeInKb < this.constants.newPackageFileSizeLimit){
          this.newPackageForm.controls.draw.setValue(reader.result.split(',')[1]);
          this.newPackageForm.controls.drawFileName.setValue(file.name);
        }else{
          this.newPackageForm.controls.draw.setValue(null);
          this.newPackageForm.controls.drawFileName.setValue(null);
          this.openMessageBox(this.constants.newPackageDrawErrorFile,'error');
        }
      }
      if(btnType === 'picture' ){
        if (this.constants.newPackagePhotoAllowedTypes.includes(contentType) && fileSizeInKb < this.constants.newPackageFileSizeLimit){
          this.newPackageForm.controls.phot.setValue(reader.result.split(',')[1]);
          this.newPackageForm.controls.photFileName.setValue(file.name);
        }else{
          this.newPackageForm.controls.phot.setValue(null);
          this.newPackageForm.controls.photFileName.setValue(null);
          this.openMessageBox(this.constants.newPackagePhotoErrorFile,'error');
        }
      }
     };
    reader.onerror = () => { alert('Error while uploading') }
  }

  onCancel(){
    //go to PPF page
    this.location.back();
  }

  omitSpecialChars(event:any){
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 8 || (charCode >= 48 && charCode <= 57) || charCode === 45) {
      return true;
    }
    event.preventDefault();
    return false;
  }
  onPaste(event:any){
    event.preventDefault();
    return false;
  }
  changeMM(event:any,formControlName:string){
    const inchdata = Math.round((event.target.value/25.4) *2)/2;
    this.newPackageForm.controls[`${formControlName}`].setValue(Math.round(inchdata * 25.4))
  }

  openMessageBox(message:string, type: string){
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  addNewPackage(){
    this.newPackageForm.markAllAsTouched();
    if(this.newPackageForm.controls['description'].value?.length > 20){
      this.openMessageBox('Description: allowed characters are 20','error');
    }
    if(this.newPackageForm.valid){
      let body = this.newPackageForm.value;
      body = {...body, username : this.userinfo.TOYOTAGUID || localStorage.getItem('toyotaGUID')}
      this.ppfService.addNewPackage(this.rowNumber, body).subscribe({
        next: (res:any) =>{
          if(res.pkgCodeDetail.Error === true){
            this.openMessageBox(res.pkgCodeDetail.ErrorMessage,'error');
          }
          else{
            // Enhancement
            if (this.rowNumber === '2' || this.rowNumber === '3') {
              const msg = `The new Dunnage code ${res.pkgCodeDetail.PACKAGECODE} generated Successfully.`;
              this.openMessageBox(msg, 'success')
            }
            this.globalState.setNewPackagePPF({rowNumber : this.rowNumber,data : res})
            this.location.back();
          }
        },
      })
    }
  }

  ngOnDestroy(): void {
    this.userInfoSubscriber?.unsubscribe();
  }

}
