import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagePopupToastComponent } from '../message-popup-toast/message-popup-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConstantsService } from 'src/app/services/constant.service';
import { DocumentationService } from 'src/app/internal-vpacs/services-internal/standard-reports/documentation.service';

@Component({
	selector: 'tracking-backup-btn-cell-render',
	template: `
        <div *ngIf = "this.colId">
            <span class="btn btn-link fw-weight-normal" (click)= "partNumberPPF()">{{this.params.PARTNUMBER}}</span>
        </div>
        <div *ngIf = "!this.colId">
            <span (click)= "generateDocument(this.params.id, this.params.filename)" style="cursor:'pointer'" class="fw-bold text-primary">{{this.params.filename}}</span>
        </div>
    `
})



export class DocumentDownload implements ICellRendererAngularComp {

    constructor(
        public documentService: DocumentationService, 
        public router: Router,
        public message: MatSnackBar,
        private readonly route: ActivatedRoute,
        private readonly constants: ConstantsService){}
  
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

	public params: any;
    public colId = false;

	agInit(params: any): void {
		this.params = params.data;       
        if(params.column.colId === 'PARTNUMBER'){
            this.colId = true
        }
	}

    openMessageBox(message:string, type: string){
        this.message.openFromComponent(MessagePopupToastComponent, {
          duration: 3000,
          data: {
            type: type,
            message: message
          }
        })
      }
  
    generateDocument(id:any,filename:any) {  
        this.documentService.downloadFile(id, filename).subscribe({
            next: (response:any) =>{
                window.open(response.body)
            },
            error: ()=>{
                this.openMessageBox('Error downloading the file', 'error')
            },
            complete : ()=>{
                this.openMessageBox('File downloaded successfully', 'success')
            }
        })
    }

    partNumberPPF(){
        const query:any = {
            'plantCode': this.params.PLANTCODE, 
            'partNumber': this.params.PARTNUMBER,
            'supplierCode' : this.params.SUPPLIERCODE,  
            'pkgStartDate': this.params.PKGSTARTDATE,  
            'ppfNumber' : this.params.PPFCODE,
            'ISUPDATEPPF': this.params?.ISUPDATEPPF,            
          };
        const plantCode = this.constants.getTypeFromPlantCode(this.params.PLANTCODE)
        if (plantCode === 'SERVICE') {
            this.router.navigate([`serviceparts-pdf/${this.params.PPFCODE}/${0}/${this.params.PARTNUMBER}/${2}/${1}`], { relativeTo: this.route })
        } else if(plantCode === 'ASSY'){
            this.router.navigate([`accessoryParts-pdf/${this.params.PPFCODE}/${0}/${this.params.PARTNUMBER}/${2}/${1}`], { relativeTo: this.route })
        }
        else {
            sessionStorage.setItem('PPFqueryParams', JSON.stringify(query))
            this.router.navigate(['ppf', this.params.SUPPLIERCODE, 'readOnly'], { relativeTo: this.route })
        }
    }
}
